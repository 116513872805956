<template>
	<div class="how_it_work_page span">
		<!--Banner-->
		<section class="howit_banner marginTop_fixed">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h1>How it <span>works</span></h1>
						<h3>
							Positioning a website <span>is now easy</span> and not expensive
						</h3>
					</div>
				</div>
			</div>
		</section>
		<!--end-->

		<!--Sub Banner-->
		<section class="howit_subbanner">
			<div class="container eleven80">
				<div class="row">
					<div class="col-md-6">
						<ul class="how-it-number">
							<svg
								width="23"
								height="52"
								viewBox="0 0 23 52"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M19.6875 0.0078125C21.7188 0.0078125 22.7344 1.02344 22.7344 3.05469V48.875C22.7344 50.9583 21.7188 52 19.6875 52H15.0391C13.0078 52 11.9922 50.9583 11.9922 48.875V8.875H3.35938C2.29167 8.875 1.4974 8.58854 0.976562 8.01562C0.481771 7.44271 0.416667 6.60938 0.78125 5.51562L1.60156 2.89844C1.91406 1.88281 2.35677 1.15365 2.92969 0.710938C3.52865 0.242188 4.33594 0.0078125 5.35156 0.0078125H19.6875Z"
									fill="#0F393B"
								/>
							</svg>
							<p>
								<b>To start with, <span>you won't need</span></b> a SEO
								specialist who can provide the right words to properly compete
								on Google, <b><span>our system does it for you.</span></b>
							</p>
						</ul>
					</div>
					<div class="col-md-6">
						<div class="how-it-number">
							<svg
								width="41"
								height="53"
								viewBox="0 0 41 53"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M36.9297 44.2109C37.9193 44.2109 38.6875 44.4844 39.2344 45.0312C39.7812 45.5521 40.0547 46.2943 40.0547 47.2578V49.875C40.0547 50.8646 39.7812 51.6328 39.2344 52.1797C38.6875 52.7266 37.9193 53 36.9297 53H5.25C4.26042 53 3.49219 52.7266 2.94531 52.1797C2.39844 51.6328 2.125 50.8646 2.125 49.875V47.6484C2.125 46.0078 2.78906 44.6146 4.11719 43.4688C8.28385 39.6927 13.0625 35.1094 18.4531 29.7188C21.6302 26.5417 23.9219 23.9505 25.3281 21.9453C26.7604 19.9401 27.4766 17.974 27.4766 16.0469C27.4766 13.7812 26.7344 11.9844 25.25 10.6562C23.7917 9.32812 21.8646 8.66406 19.4688 8.66406C15.7708 8.66406 12.099 10.487 8.45312 14.1328C6.89062 15.5391 5.41927 15.4219 4.03906 13.7812L2.51562 12.0234C1.23958 10.513 1.39583 9.0026 2.98438 7.49219C7.88021 2.51823 13.7526 0.03125 20.6016 0.03125C25.7578 0.03125 29.9245 1.47656 33.1016 4.36719C36.2786 7.23177 37.8672 10.9948 37.8672 15.6562C37.8672 18.2083 37.2552 20.6562 36.0312 23C34.8073 25.3438 32.8672 28.0391 30.2109 31.0859C29.1432 32.3099 27.8021 33.7031 26.1875 35.2656C24.599 36.8021 23.1797 38.1432 21.9297 39.2891C20.6797 40.4349 18.8438 42.0755 16.4219 44.2109H36.9297Z"
									fill="#0F393B"
								/>
							</svg>
							<p>
								<b>Then, <span>you won't need</span></b> a copywriter who will
								write using the right text with the keywords identified because
								<b><span>our system does it for you.</span></b>
							</p>
						</div>
					</div>
					<div class="col-md-6 offset-md-3">
						<div class="how-it-number">
							<svg
								width="41"
								height="54"
								viewBox="0 0 41 54"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M32.0703 25.3828C34.8828 26.7109 37.0703 28.4818 38.6328 30.6953C40.1953 32.8828 40.9766 35.4609 40.9766 38.4297C40.9766 42.987 39.1406 46.724 35.4688 49.6406C31.7969 52.5312 27.0443 53.9766 21.2109 53.9766C17.7995 53.9766 14.5052 53.2995 11.3281 51.9453C8.17708 50.5911 5.52083 48.8333 3.35938 46.6719C1.69271 45.1094 1.45833 43.7292 2.65625 42.5312L4.72656 40.3438C6.28906 38.7812 7.72135 38.6771 9.02344 40.0312C10.5599 41.5938 12.3698 42.8698 14.4531 43.8594C16.5365 44.8229 18.6849 45.3047 20.8984 45.3047C23.6328 45.3047 25.8333 44.6146 27.5 43.2344C29.1667 41.8281 30 39.9922 30 37.7266C30 35.2266 29.0104 33.2474 27.0312 31.7891C25.0521 30.3047 22.4479 29.5625 19.2188 29.5625H17.2656C15.2604 29.5625 14.2578 28.5208 14.2578 26.4375V24.9922C14.2578 22.9089 15.2604 21.8672 17.2656 21.8672H19.5312C22.1615 21.8672 24.3229 21.2292 26.0156 19.9531C27.7083 18.651 28.5547 16.9974 28.5547 14.9922C28.5547 13.1172 27.7995 11.5807 26.2891 10.3828C24.7786 9.1849 22.8385 8.58594 20.4688 8.58594C16.6667 8.58594 13.3073 9.82292 10.3906 12.2969C8.77604 13.599 7.31771 13.4818 6.01562 11.9453L4.49219 10.0312C3.89323 9.22396 3.67188 8.46875 3.82812 7.76562C3.98438 7.0625 4.4401 6.41146 5.19531 5.8125C9.85677 1.95833 15.4036 0.03125 21.8359 0.03125C26.9661 0.03125 31.1589 1.34635 34.4141 3.97656C37.6693 6.58073 39.2969 9.9401 39.2969 14.0547C39.2969 19.0026 36.888 22.7786 32.0703 25.3828Z"
									fill="#0F393B"
								/>
							</svg>
							<p>
								<b>Last but not least, <span>you won't need</span></b> a digital
								PR to ensure that your website communicates your project or idea
								to the world with a constant update because
								<b><span>our system does it for you.</span></b>
							</p>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<h3 class="howIt_spot_title">
							<span>SEO Suite</span> keeps you always in sync with the market
							and one step ahead of the competition thanks to intelligent
							process automation.
						</h3>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<ul class="how-it-step">
							<li class="step1">
								If you have <span>10</span> pages, you may be able to go it
								alone.
							</li>
							<li class="step2">
								If you have <span>100</span> pages, however, you must hire at
								least <span>3 people</span> or <span>contact an agency.</span>
							</li>
							<li class="step3">
								With <span>1000 or more pages</span>, you must invest some
								<span>serious cash</span> or
								<span>risk leaving some business</span> on the table.
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		<!--end-->

		<!--section col/6/6 -->
		<section class="colsix">
			<div class="container eleven80">
				<div class="row">
					<div class="col-md-6">
						<h2>What’s <span>SEO Suite</span></h2>
					</div>
					<div class="col-md-6 mbbg13">
						<div class="corefeatures">
							<p>
								SEO Suite improves the performance of your +1000-page website in
								a professional and automatic manner.
							</p>
							<p>
								Let artificial intelligence deal with the content using keywords
								indicated by Big Data analysis.
							</p>
							<p>
								Take advantage of digital PR automation and the granular
								personalization of email marketing to your customers.
							</p>
							<p>
								For example, you will know where your customers were and when
								they opened the email, which button they clicked on, how they
								navigated to and around the site, and how much time did they
								spend on each page. And all this for each prospect and email.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--END-->

		<!--section col/6/6/extra -->
		<section class="colsix_extra">
			<div class="container eleven80">
				<div class="row">
					<div class="col-md-6">
						<h2>See what’s inside the <span>Suite</span></h2>
					</div>
					<div class="col-md-6">
						<div class="core_content">
							<h6>Core features</h6>
							<ul>
								<li>
									<router-link :to="`/${$i18n.locale}/technical-analysis`"
										><svg
											width="80"
											height="80"
											viewBox="0 0 80 80"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g filter="url(#filter0_d_1_1306)">
												<rect
													x="15"
													y="15"
													width="50"
													height="50"
													rx="10"
													fill="#347362"
												/>
											</g>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M52.8329 43.5516L52.4285 42.85C52.0864 42.2562 51.3283 42.0514 50.7337 42.3921C50.4507 42.5588 50.113 42.6061 49.7951 42.5235C49.4772 42.441 49.2051 42.2354 49.039 41.952C48.9321 41.7719 48.8746 41.5668 48.8725 41.3574C48.8821 41.0216 48.7554 40.6962 48.5213 40.4554C48.2872 40.2145 47.9655 40.0787 47.6296 40.0789H46.815C46.4859 40.0789 46.1704 40.21 45.9382 40.4432C45.7061 40.6765 45.5765 40.9926 45.5781 41.3217C45.5683 42.0011 45.0147 42.5467 44.3353 42.5467C44.1258 42.5445 43.9207 42.4871 43.7406 42.3802C43.146 42.0395 42.3879 42.2443 42.0458 42.8381L41.6117 43.5516C41.27 44.1446 41.4721 44.9023 42.0637 45.2464C42.4482 45.4684 42.6851 45.8787 42.6851 46.3227C42.6851 46.7668 42.4482 47.1771 42.0637 47.3991C41.4728 47.7409 41.2706 48.4967 41.6117 49.0879L42.022 49.7955C42.1823 50.0848 42.4513 50.2982 42.7693 50.3886C43.0874 50.479 43.4284 50.4389 43.7168 50.2772C44.0004 50.1117 44.3383 50.0664 44.6554 50.1513C44.9725 50.2362 45.2426 50.4442 45.4056 50.7291C45.5125 50.9092 45.57 51.1144 45.5721 51.3238C45.5721 52.0102 46.1286 52.5666 46.815 52.5666H47.6296C48.3137 52.5666 48.8692 52.0138 48.8725 51.3297C48.8709 50.9996 49.0013 50.6826 49.2347 50.4492C49.4682 50.2158 49.7852 50.0853 50.1153 50.0869C50.3242 50.0925 50.5285 50.1497 50.71 50.2534C51.303 50.5951 52.0606 50.3931 52.4047 49.8015L52.8329 49.0879C52.9986 48.8034 53.0441 48.4646 52.9593 48.1465C52.8744 47.8284 52.6663 47.5573 52.3809 47.3931C52.0956 47.229 51.8874 46.9578 51.8026 46.6397C51.7178 46.3216 51.7633 45.9828 51.929 45.6984C52.0368 45.5102 52.1928 45.3542 52.3809 45.2464C52.969 44.9025 53.1705 44.1493 52.8329 43.5576V43.5516Z"
												stroke="#F5F3EE"
												stroke-width="0.974492"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M47.2257 48.0353C48.1715 48.0353 48.9383 47.2686 48.9383 46.3227C48.9383 45.3769 48.1715 44.6101 47.2257 44.6101C46.2798 44.6101 45.5131 45.3769 45.5131 46.3227C45.5131 47.2686 46.2798 48.0353 47.2257 48.0353Z"
												stroke="#F5F3EE"
												stroke-width="0.974492"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M38.3873 43.5516L37.983 42.85C37.6409 42.2562 36.8827 42.0514 36.2882 42.3921C36.0052 42.5588 35.6675 42.6061 35.3495 42.5235C35.0316 42.441 34.7596 42.2354 34.5934 41.952C34.4865 41.7719 34.4291 41.5668 34.4269 41.3574C34.4366 41.0216 34.3099 40.6962 34.0758 40.4554C33.8416 40.2145 33.52 40.0787 33.1841 40.0789H32.3694C32.0403 40.0789 31.7248 40.21 31.4927 40.4432C31.2606 40.6765 31.1309 40.9926 31.1325 41.3217C31.1228 42.0011 30.5692 42.5467 29.8897 42.5467C29.6803 42.5445 29.4751 42.4871 29.295 42.3802C28.7005 42.0395 27.9424 42.2443 27.6003 42.8381L27.1662 43.5516C26.8245 44.1446 27.0265 44.9023 27.6181 45.2464C28.0026 45.4684 28.2395 45.8787 28.2395 46.3227C28.2395 46.7668 28.0026 47.1771 27.6181 47.3991C27.0273 47.7409 26.825 48.4967 27.1662 49.0879L27.5765 49.7955C27.7368 50.0848 28.0057 50.2982 28.3238 50.3886C28.6418 50.479 28.9828 50.4389 29.2713 50.2772C29.5548 50.1117 29.8927 50.0664 30.2098 50.1513C30.527 50.2362 30.7971 50.4442 30.9601 50.7291C31.067 50.9092 31.1244 51.1144 31.1266 51.3238C31.1266 52.0102 31.683 52.5666 32.3694 52.5666H33.1841C33.8682 52.5666 34.4236 52.0138 34.4269 51.3297C34.4253 50.9996 34.5558 50.6826 34.7892 50.4492C35.0226 50.2158 35.3396 50.0853 35.6697 50.0869C35.8787 50.0925 36.083 50.1497 36.2644 50.2534C36.8574 50.5951 37.615 50.3931 37.9592 49.8015L38.3873 49.0879C38.553 48.8034 38.5985 48.4646 38.5137 48.1465C38.4289 47.8284 38.2207 47.5573 37.9354 47.3931C37.65 47.229 37.4419 46.9578 37.3571 46.6397C37.2722 46.3216 37.3177 45.9828 37.4834 45.6984C37.5912 45.5102 37.7472 45.3542 37.9354 45.2464C38.5234 44.9025 38.725 44.1493 38.3873 43.5576V43.5516Z"
												stroke="#F5F3EE"
												stroke-width="0.974492"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M32.7797 48.0353C33.7256 48.0353 34.4924 47.2686 34.4924 46.3227C34.4924 45.3769 33.7256 44.6101 32.7797 44.6101C31.8339 44.6101 31.0671 45.3769 31.0671 46.3227C31.0671 47.2686 31.8339 48.0353 32.7797 48.0353Z"
												stroke="#F5F3EE"
												stroke-width="0.974492"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M45.6095 30.5512L45.2051 29.8495C44.863 29.2557 44.1049 29.0509 43.5104 29.3916C43.2273 29.5583 42.8896 29.6056 42.5717 29.5231C42.2538 29.4405 41.9817 29.2349 41.8156 28.9515C41.7087 28.7714 41.6513 28.5663 41.6491 28.3569C41.6587 28.0211 41.5321 27.6958 41.2979 27.4549C41.0638 27.2141 40.7421 27.0782 40.4063 27.0784H39.5916C39.2625 27.0784 38.947 27.2095 38.7149 27.4427C38.4827 27.676 38.3531 27.9921 38.3547 28.3212C38.3449 29.0006 37.7914 29.5463 37.1119 29.5462C36.9024 29.544 36.6973 29.4866 36.5172 29.3797C35.9227 29.039 35.1645 29.2438 34.8224 29.8376L34.3883 30.5512C34.0467 31.1442 34.2487 31.9018 34.8403 32.2459C35.2248 32.4679 35.4617 32.8782 35.4617 33.3223C35.4617 33.7663 35.2248 34.1766 34.8403 34.3986C34.2494 34.7404 34.0472 35.4962 34.3883 36.0874L34.7987 36.795C34.9589 37.0843 35.2279 37.2977 35.5459 37.3881C35.864 37.4785 36.205 37.4384 36.4934 37.2767C36.777 37.1113 37.1149 37.0659 37.432 37.1508C37.7491 37.2357 38.0192 37.4437 38.1822 37.7287C38.2891 37.9088 38.3466 38.1139 38.3487 38.3233C38.3487 39.0097 38.9052 39.5661 39.5916 39.5661H40.4063C41.0903 39.5661 41.6458 39.0133 41.6491 38.3293C41.6475 37.9991 41.7779 37.6821 42.0113 37.4487C42.2448 37.2153 42.5618 37.0848 42.8919 37.0864C43.1008 37.092 43.3051 37.1492 43.4866 37.2529C44.0796 37.5946 44.8372 37.3926 45.1813 36.801L45.6095 36.0874C45.7752 35.8029 45.8207 35.4642 45.7359 35.1461C45.6511 34.828 45.4429 34.5568 45.1576 34.3926C44.8722 34.2285 44.6641 33.9573 44.5792 33.6392C44.4944 33.3211 44.5399 32.9823 44.7056 32.6979C44.8134 32.5097 44.9694 32.3537 45.1576 32.2459C45.7456 31.902 45.9472 31.1488 45.6095 30.5571V30.5512Z"
												stroke="#F5F3EE"
												stroke-width="0.974492"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M40.0024 35.0346C40.9483 35.0346 41.715 34.2678 41.715 33.322C41.715 32.3761 40.9483 31.6094 40.0024 31.6094C39.0566 31.6094 38.2898 32.3761 38.2898 33.322C38.2898 34.2678 39.0566 35.0346 40.0024 35.0346Z"
												stroke="#F5F3EE"
												stroke-width="0.974492"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<defs>
												<filter
													id="filter0_d_1_1306"
													x="0"
													y="0"
													width="80"
													height="80"
													filterUnits="userSpaceOnUse"
													color-interpolation-filters="sRGB"
												>
													<feFlood
														flood-opacity="0"
														result="BackgroundImageFix"
													/>
													<feColorMatrix
														in="SourceAlpha"
														type="matrix"
														values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
														result="hardAlpha"
													/>
													<feOffset />
													<feGaussianBlur stdDeviation="7.5" />
													<feComposite in2="hardAlpha" operator="out" />
													<feColorMatrix
														type="matrix"
														values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
													/>
													<feBlend
														mode="normal"
														in2="BackgroundImageFix"
														result="effect1_dropShadow_1_1306"
													/>
													<feBlend
														mode="normal"
														in="SourceGraphic"
														in2="effect1_dropShadow_1_1306"
														result="shape"
													/>
												</filter>
											</defs>
										</svg>
										<span>Technical Analysis</span></router-link
									>
								</li>
								<li>
									<router-link :to="`/${$i18n.locale}/content-analysis`"
										><svg
											width="80"
											height="80"
											viewBox="0 0 80 80"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g filter="url(#filter0_d_1_1335)">
												<rect
													x="15"
													y="15"
													width="50"
													height="50"
													rx="10"
													fill="#EF8451"
												/>
											</g>
											<path
												d="M35 33H45M35 36.75H45M35 40.5H40.625M46.875 43.625H43.75M40.625 43.625H43.75M43.75 43.625V49.25M50 31.125V49.875C50 51.2557 48.8807 52.375 47.5 52.375H32.5C31.1193 52.375 30 51.2557 30 49.875V31.125C30 29.7443 31.1193 28.625 32.5 28.625H47.5C48.8807 28.625 50 29.7443 50 31.125Z"
												stroke="#F5F3EE"
												stroke-width="1.875"
												stroke-linejoin="round"
											/>
											<defs>
												<filter
													id="filter0_d_1_1335"
													x="0"
													y="0"
													width="80"
													height="80"
													filterUnits="userSpaceOnUse"
													color-interpolation-filters="sRGB"
												>
													<feFlood
														flood-opacity="0"
														result="BackgroundImageFix"
													/>
													<feColorMatrix
														in="SourceAlpha"
														type="matrix"
														values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
														result="hardAlpha"
													/>
													<feOffset />
													<feGaussianBlur stdDeviation="7.5" />
													<feComposite in2="hardAlpha" operator="out" />
													<feColorMatrix
														type="matrix"
														values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
													/>
													<feBlend
														mode="normal"
														in2="BackgroundImageFix"
														result="effect1_dropShadow_1_1335"
													/>
													<feBlend
														mode="normal"
														in="SourceGraphic"
														in2="effect1_dropShadow_1_1335"
														result="shape"
													/>
												</filter>
											</defs>
										</svg>
										<span>Content Analysis</span></router-link
									>
								</li>
								<li>
									<router-link :to="`/${$i18n.locale}/brand-reputation`"
										><svg
											width="80"
											height="80"
											viewBox="0 0 80 80"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g filter="url(#filter0_d_1_1321)">
												<rect
													x="15"
													y="15"
													width="50"
													height="50"
													rx="10"
													fill="#EDCB50"
												/>
											</g>
											<circle
												cx="31.75"
												cy="45.5"
												r="2.75"
												stroke="#F5F3EE"
												stroke-width="1.83333"
											/>
											<circle
												r="2.75"
												transform="matrix(1 0 0 -1 48.25 45.5)"
												stroke="#F5F3EE"
												stroke-width="1.83333"
											/>
											<circle
												r="2.75"
												transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 40 31.75)"
												stroke="#F5F3EE"
												stroke-width="1.83333"
											/>
											<path
												d="M35.875 49.625C35.875 49.625 37.7179 51 40 51C42.2821 51 44.125 49.625 44.125 49.625"
												stroke="#F5F3EE"
												stroke-width="1.83333"
												stroke-linejoin="round"
											/>
											<path
												d="M48.25 40.6875C48.25 40.6875 48.25 39.3125 47.3541 37.5191C46.4582 35.7258 44.8125 34.5 44.8125 34.5"
												stroke="#F5F3EE"
												stroke-width="1.83333"
												stroke-linejoin="round"
											/>
											<path
												d="M31.75 40.6875C31.75 40.6875 31.75 39.3125 32.6459 37.5191C33.5418 35.7258 35.1875 34.5 35.1875 34.5"
												stroke="#F5F3EE"
												stroke-width="1.83333"
												stroke-linejoin="round"
											/>
											<defs>
												<filter
													id="filter0_d_1_1321"
													x="0"
													y="0"
													width="80"
													height="80"
													filterUnits="userSpaceOnUse"
													color-interpolation-filters="sRGB"
												>
													<feFlood
														flood-opacity="0"
														result="BackgroundImageFix"
													/>
													<feColorMatrix
														in="SourceAlpha"
														type="matrix"
														values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
														result="hardAlpha"
													/>
													<feOffset />
													<feGaussianBlur stdDeviation="7.5" />
													<feComposite in2="hardAlpha" operator="out" />
													<feColorMatrix
														type="matrix"
														values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
													/>
													<feBlend
														mode="normal"
														in2="BackgroundImageFix"
														result="effect1_dropShadow_1_1321"
													/>
													<feBlend
														mode="normal"
														in="SourceGraphic"
														in2="effect1_dropShadow_1_1321"
														result="shape"
													/>
												</filter>
											</defs></svg
										><span> Web Reputation</span></router-link
									>
								</li>
								<li>
									<router-link :to="`/${$i18n.locale}/crm`"
										><svg
											width="80"
											height="80"
											viewBox="0 0 80 80"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g filter="url(#filter0_d_1_1330)">
												<rect
													x="15"
													y="15"
													width="50"
													height="50"
													rx="10"
													fill="#0F393B"
												/>
											</g>
											<path
												d="M29 46.111V33.8888C29 32.5388 30.0944 31.4443 31.4444 31.4443H48.5556C49.9056 31.4443 51 32.5388 51 33.8888V46.111C51 47.461 49.9056 48.5554 48.5556 48.5554H31.4444C30.0944 48.5554 29 47.461 29 46.111Z"
												stroke="#F5F3EE"
												stroke-width="1.83333"
												stroke-linejoin="round"
											/>
											<path
												d="M29.6111 32.0557L38.3827 39.7953C39.3067 40.6106 40.6932 40.6106 41.6173 39.7953L50.3889 32.0557"
												stroke="#F5F3EE"
												stroke-width="1.83333"
												stroke-linejoin="round"
											/>
											<defs>
												<filter
													id="filter0_d_1_1330"
													x="0"
													y="0"
													width="80"
													height="80"
													filterUnits="userSpaceOnUse"
													color-interpolation-filters="sRGB"
												>
													<feFlood
														flood-opacity="0"
														result="BackgroundImageFix"
													/>
													<feColorMatrix
														in="SourceAlpha"
														type="matrix"
														values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
														result="hardAlpha"
													/>
													<feOffset />
													<feGaussianBlur stdDeviation="7.5" />
													<feComposite in2="hardAlpha" operator="out" />
													<feColorMatrix
														type="matrix"
														values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
													/>
													<feBlend
														mode="normal"
														in2="BackgroundImageFix"
														result="effect1_dropShadow_1_1330"
													/>
													<feBlend
														mode="normal"
														in="SourceGraphic"
														in2="effect1_dropShadow_1_1330"
														result="shape"
													/>
												</filter>
											</defs>
										</svg>
										<span>CRM</span></router-link
									>
								</li>
							</ul>

							<h6 class="also_how">Also includes</h6>

							<div class="servicesAccording">
								<!--start FAQ-->
								<div class="accordion faqsc" role="tablist">
									<b-card
										no-body
										v-for="(items, index) in faqs"
										:key="items.index"
									>
										<b-card-header header-tag="header" role="tab">
											<b-button
												block
												v-b-toggle="'accordion' + index"
												variant="info"
											>
												{{ items.faqQuestion }}
											</b-button>
										</b-card-header>
										<b-collapse
											:id="'accordion' + index"
											visible
											accordion="my-accordion"
											role="tabpanel"
										>
											<b-card-body>
												<p class="card-text" v-html="items.faqAnswer"></p>
											</b-card-body>
										</b-collapse>
									</b-card>
								</div>
								<!--end faq-->
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--END-->
	</div>
</template>

<script>
	import MainPage from "@/layouts/MainPage.vue";
	import eng from "../locales/how_it_work_also_includes_en.json";
	import ita from "../locales/how_it_work_also_includes_it.json";
	export default {
		data: () => ({
			eng: eng,
			ita: ita,
		}),
		computed: {
			faqs() {
				return this.$i18n.locale === "en" ? this.eng : this.ita;
			},
		},
		//this for layout call
		created() {
			this.$parent.$emit("update:layout", MainPage);
		},
		//this for layout render
		render() {
			return this.$slots.default[0];
		},
		//Meta info title + description + others meta
		metaInfo() {
			return {
				title: "SEO Suite",
				htmlAttrs: {
					lang: this.$i18n.locale,
				},
				meta: [
					{ name: "description", content: "SEO Suite" },
					{ property: "og:title", content: "SEO Suite" },
					{ property: "og:site_name", content: "SEO Suite" },
					{ property: "og:type", content: "website" },
					{ name: "robots", content: "index,follow" },
				],
			};
		},
		//end meta info
	};
</script>

<style lang="scss" scoped></style>
